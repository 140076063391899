.header {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--theme-color);
    border-bottom: 1px solid lightgray;
}

.header-logo-container {
    padding: 6px;
    background-color: white;
    cursor: pointer;
    display: flex;
    justify-content: center;
}

.header-logo {
    height: 73px;
    width: auto;
}

.header-nav-container {
    padding: 6px;
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
