@font-face {
    font-family: 'Novalis JY';
    src: url('./assets/font/NovalisJY-Light.woff') format('woff2'),
        url('./assets/font/NovalisJY-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Novalis JY';
    src: url('./assets/font/NovalisJY-Bold.woff2') format('woff2'),
        url('./assets/font/NovalisJY-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Novalis JY';
    src: url('./assets/font/NovalisJY-Medium.woff2') format('woff2'),
        url('./assets/font/NovalisJY-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

html {
    --theme-color: #327BBF;
}

body {
    margin: 0;
    font-size: 14px;
    font-family: 'Open-sans', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: 'Open-sans', sans-serif;
}

a:hover {
    color: white;
}

.a-override:hover {
    color: #007bff !important;
}

button {
    outline: none !important;
}

#root {
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
}

/* --------------------------------------------- */
/* ---------- OVERWRITE MATERIAL UI ------------ */
/* --------------------------------------------- */

.MuiButton-root {
    border-radius: 20px !important;
    font-family: 'Novalis JY' !important;
}

/* --------------------------------------------- */
/* ---------- OVERWRITE SIMPLE BAR  ------------ */
/* --------------------------------------------- */

.simplebar-track {
    pointer-events: all !important;
}

.simplebar-horizontal {
    bottom: -10px !important;
}

/* --------------------------------------------- */
/* ------------------ CLASSES ------------------ */
/* --------------------------------------------- */

.mabd-font {
    font-family: 'Novalis JY';
}

.input-title {
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    color: gray;
}

.form-control {
    font-size: 14px;
}

.title-status-list {
    text-transform: uppercase;
    font-size: 12px;
}

/* ---------------- COLORS ---------------- */
.primary-color {
    color: #3f51b5;
}

.secondary-color {
    color: #f50057;
}

.light-background-color {
    background-color: #DBF2FF;
}

.subtitle-primary-color {
    font-weight: 600;
    font-size: 12px;
    color: #3E5689;
    text-transform: uppercase;
}

/* ---------------- MENU ---------------- */
.menu {
    background-color: var(--theme-color);
    color: white;
    box-shadow: 0 3px 2px -2px rgba(0, 0, 0, 0.2);
}

.menu-item {
    padding: 6px 12px;
    font-family: 'Novalis JY';
    text-align: center;
    cursor: pointer;
}

    .menu-item:hover {
        background-color: rgba(255, 255, 255, 0.1);
    }

    .menu-item-selected {
        background-color: rgba(255, 255, 255, 0.2);
    }

.side-drawer-menu-item {
    padding: 12px;
    font-family: 'Novalis JY';
    text-align: center;
    cursor: pointer;
}

    .side-drawer-menu-item:hover {
        background-color: rgba(0, 0, 0, 0.1);
    }

    .side-drawer-menu-item-selected {
        background-color: rgba(0, 0, 0, 0.1);
    }

/* ---------------- HOME ---------------- */
.unauthenticated {
    flex: 1;
    background-color: var(--theme-color);
}

.unauthenticated-container {
    border-radius: 4px;
    background-color: white;
    height: fit-content;
    width: inherit;
}

#password-icon {
    position: absolute;
    right: 2px;
    z-index: 4;
    width: 38px;
    height: 38px;
    padding: 0px;
    outline: none;
}

/* ---------------- VERIFY ---------------- */
.verify {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    background-color: var(--theme-color);
}

.verify-header {
    padding: 12px;
    background-color: var(--theme-color);
}

/* ---------------- FEEDBACK ---------------- */
.feedback {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    padding: 10px;
}

/* ---------------- LIST ITEM ---------------- */
.list-item {
    cursor: pointer;
    padding: 24px;
    border-radius: 4px;
    border: 1px solid lightgray;
    margin-bottom: 10px;
}

    .list-item:hover {
        background-color: rgba(0, 0, 0, 0.1);
    }

.simple-list:hover, .simple-item-selected:hover {
    background-color: rgba(0, 0, 0, 0.1);
    cursor: pointer;
}

.simple-item-selected {
    background-color: rgba(0, 0, 0, 0.08);
}

/* ---------------- DIALOG TITLE ---------------- */
.dialog-title {
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
}

/* ---------------- INPUT FILE ---------------- */
.div-input-file {
    display: flex;
    align-items: center;
}
  
    .label-input-file {
        padding: 10px 15px;
        border-radius: 3px;
        border: 1px solid lightgray;
        cursor: pointer;
        margin-bottom: 0px;
    }
  
    .label-input-file:hover {
        border-color: #1976d2;
        color: #1976d2;
    }

/* ---------------- TOOLBAR ---------------- */
.toolbar {
    padding: 24px;
}

/* ---------------- SUBHEADER ---------------- */
.subheader {
    padding: 20px 24px;
    font-size: 16px;
    z-index: 1;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    font-family: 'Novalis JY';
}

/* ---------------- CONTAINER ---------------- */
.main-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    padding: 0px 24px;
}

.main-container-item {
    flex: 1 1 auto;
    overflow: auto;
    height: 300px;
    min-height: 300px;
}

/* ---------------- LIST ---------------- */
.list-sticky {
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: white;
}

.list-headers {
    background-color: var(--theme-color);
    color: white;
    display: flex;
    flex-direction: row;
    width: fit-content;
}

.list-filters {
    display: flex;
    flex-direction: row;
    width: fit-content;
    background-color: white;
}

.list-data {
    display: flex;
    width: fit-content;
    cursor: pointer;
}

    .list-data:hover {
        background-color: rgba(0, 0, 0, 0.08);
    }

/* ---------------- PAPER ---------------- */
.paper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
    width: 100%;
    padding: 5px;
}

    .paper:hover {
        background-color: rgba(0, 0, 0, 0.1);
    }

/* ---------------- AVATAR ---------------- */
.avatar {
    margin: 0px auto 0px auto;
    width: 100px !important;
    height: 100px !important;
}

/* ---------------- NOTE ---------------- */
.note {
    height: 260px;
    width: 260px;
    min-height: 260px;
    min-width: 260px;
    margin-left: 15px;
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
	position: relative;
	background: rgba(0, 0, 0, 0.1);
    padding: 0px;
    cursor: pointer;
    -webkit-box-shadow: -8px 8px 6px -2px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: -8px 8px 6px -2px rgba(0, 0, 0, 0.5);
    box-shadow: -8px 8px 6px -2px rgba(0, 0, 0, 0.5);
}

    .note:hover {
        background: lightgray;
    }

    .note:before {
        content: "";
        display: block;
        position: absolute;
        border-color: #FFF #FFF #aaa #FFF;
        border-style: solid;
        border-width: 0px 30px 30px 0px;
        right: 0px;
        height: 0px;
        width: 0px;
    }

.note-content {
    padding: 24px 24px 0px 24px;
    display: flex;
    flex-direction: column;
    flex: 1;
    max-width: -webkit-fill-available;
    overflow-wrap: break-word;
}

    .note-content span {
        overflow: auto;
        max-height: 150px;
    }

/* ---------------- TOOGLE BUTTON ---------------- */
.toggle-button {
    box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
}

    .toggle-button span {
        color: rgba(0, 0, 0, 0.87);
    }

/* ---------------- FORMATION SESSION ---------------- */
.formation-session {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.1);
    padding: 12px;
    border-radius: 4px;
    cursor: pointer;
}

/* ---------------- HOVER ---------------- */
.hover:hover {
    background-color: lightgray;
}

/* ---------------- FORMATION PARTICIPATIONS ---------------- */
.day-programs-td {
    padding: 12px;
    border: 1px solid lightgray;
    min-width: 200px;
    width: 200px;
    max-width: 200px;
    text-align: center;
}

.users-td {
    padding: 12px;
    border: 1px solid lightgray;
    min-width: 300px;
    width: 300px;
    max-width: 300px;
    text-align: center;
}

/* ---------------- PURCHASE CONDITIONS ---------------- */
.purchase-conditions-link:hover {
    color: initial !important;
}

/* -------------------- PELLET --------------------- */
.pellet-primary {
    width: 25px; 
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: white;
    background-color: #3f51b5;
    box-shadow: 0px 0px 5px 1px gray;
}

/* -------------------- COLUMN LIST --------------------- */
.column-list {
    padding: 10px;
    width: 280px;
    overflow-wrap: break-word;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
}

/* ---------------- INPUT COMPARATOR ---------------- */
.input-comparator {
    background-color: lightgray;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
}

    .input-comparator:hover {
        cursor: pointer;
        background-color: gray;
    }

/* ---------------- INPUT COMPARATOR ---------------- */
.subscription-links {
    height: 160px;
    width: 160px;
    border-radius: 4px;
    border: 1px solid #3f51b5;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

    .subscription-links:hover {
        cursor: pointer;
        background-color: #3f51b5;
        color: white;
    }

/* ---------------- DOMAINS ---------------- */
.domains-menu {
    background-color: var(--theme-color);
    color: white;
    box-shadow: 0 6px 2px -2px rgba(0, 0, 0, 0.2);
}

.domains-menu-item {
    cursor: pointer;
    text-align: center;
    padding: 12px;
}
    
    .domains-menu-item:hover {
        background-color: rgba(255, 255, 255, 0.1);
    }

.domains-menu-item-selected {
    border-bottom: 2px solid white;
}

/* ---------------- BUTTON GROUPS ---------------- */
.button-group {
    font-family: 'Novalis JY' !important;
    border: none;
    color: white;
    cursor: pointer;
    opacity: 0.8;
    padding: 4px 10px;
}

    .button-group:hover {
        opacity: 1;
    }

.button-group-left {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    background-color: #0CA05A;
}

.button-group-right {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    background-color: #f50057;
}

/* ---------------- INFORMATIONS TABLE ---------------- */
.simple-td {
    padding: 12px;
    border: 1px solid lightgray;
    text-align: center;
}

.table-td {
    padding: 12px;
    border: 1px solid lightgray;
}

/* ---------------- FORMATTED TEXT ---------------- */
.text {
	overflow-wrap: break-word;
	word-break: break-word;
    white-space: pre-wrap;
}
