.subscription {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
    cursor: pointer;
    width: 100%;
    height: 100%;
}

    .subscription:hover .subscription-infos {
        background-color: rgba(0, 0, 0, 0.1);
    }

.subscription-img {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
}

.subscription-infos {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 12px;
    height: 100px;
    width: 100%;
}

.extra-background {
    background-color: rgba(50, 123, 191, 0.2);
}

.extra {
    padding: 10px;
    border: 1px solid black;
    border-radius: 4px;
    cursor: pointer;
}

    .extra:hover {
        background-color: #327BBF;
        color: white;
    }

.extra-selected {
    background-color: #327BBF;
    color: white;
}
